<template>
  <section class="addAccount panel-section">
    <div class="back-btn" @click="back">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
    </div>
    <div class="title">{{ name }} {{ appId }}</div>
    <div class="desc">普通用户编号、区块链账户地址、用户手机号</div>
    <div class="search-form flex alignFlexEnd justifySpaceBetween">
      <div class="flex">
        <div class="mr56">
          <el-input
            v-model.trim="formSearch.account"
            placeholder="选择输入查询条件后查询"
            :style="{ width: '400px' }"
          ></el-input>
        </div>
      </div>
      <div class="btn-wrap">
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button @click="onReset">重置</el-button>
      </div>
    </div>

    <Table
      ref="table"
      :baseData="baseData"
      :tableHeader="tableHeader"
      @handleCurrent="handleCurrent"
      :currentPage="formSearch.pageNo"
      :total="total"
      :isShow="isShow"
      :listLoading="listLoading"
      :isShowPagination="true"
    >
      <el-table-column fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <div class="opration check">
            <span @click="changeFreeze(scope.row)">{{scope.row.accountStatus ? "解冻" : "冻结"}}</span>
          </div>
        </template>
      </el-table-column>
    </Table>
  </section>
</template>
<script>
import API_ACCOUNT from "@/api/api_account";
//客户管理--新增客户

export default {
  name: "AddAccount",
  components: {},
  data() {
    return {
      baseData: [],
      tableHeader: this.tableHeader.channelAccount.tableHeader,
      total: 0,
      isShow: false,
      listLoading: true,
      formSearch: {
        pageNo: 1,
        pageSize: 10,
        shopId: undefined,
        account: undefined,
      },
      appId: undefined,
      name: "",
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    getDataList() {
      API_ACCOUNT.getChannelAccount(this.formSearch)
        .then(({ data }) => {
          if (data.code == 200 && data.data) {
            this.baseData = data.data.records || [];
          } else {
            this.baseData = [];
          }
          this.total = (data.data && data.data.total) || 0;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    onSearch() {
      this.getDataList();
    },
    onReset() {
      this.formSearch = {
        pageNo: 1,
        pageSize: 10,
        shopId: this.$route.query.id,
        account: undefined,
      };
      this.getDataList();
    },
    handleCurrent(val) {
      this.formSearch.pageNo = val;
      this.getDataList();
    },
    changeFreeze(res) {
      const txt = res.accountStatus ? "解冻" : "冻结";
      this.$confirm(`确定${txt}吗？`, "提示", {
        type: "warning",
      })
        .then(() => {
          API_ACCOUNT.updateAccessStatus({id: res.id}).then(({data}) => {
            if(data.code == 200) {
              this.getDataList();
              this.$message({
                message: `${txt}成功~`,
                type: "success",
              });
            }else{
              this.$message.error({
                message: data.msg || this.$CommonMsg["networkErr"],
              });
            }
          });
        })
        .catch(() => {});
    },
  },
  activated() {
    this.formSearch.shopId = this.$route.query.id;
    this.appId = this.$route.query.appId;
    this.name = decodeURIComponent(this.$route.query.name || "");
    this.getDataList();
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/_basePage.scss";
.addAccount {
  padding: 40px 48px;
  .title {
    font-family: "Microsoft YaHei UI";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #303133;
    margin: 24px 0;
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616875;
    margin: 24px auto 8px;
  }
  .search-form {
    margin-bottom: 32px;
  }
}
/deep/ .el-form-item__content {
  width: 256px;
}

.btn-wrap {
  font-size: 0;
  .el-button {
    padding: 10px 24px;
    line-height: 18px;
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
