import * as API from './request';

const comUrl = "/v1/admin/shop/";
const Account = {
  getAccountFill(params) {
    return API.post(`${comUrl}list`, params);
  },
  setJoinStatus(params) {
    return API.put(`${comUrl}status`, params);
  },
  setAccountFill(params, type) {
    return type ? API.post(comUrl, params) : API.put(comUrl, params);
  },
  getChannelAccount(params) {
    return API.post(`${comUrl}account`, params);
  },
  updateAccessStatus(params) {
    return API.patch(`${comUrl}account/${params.id}`);
  },
  getAccountCustom(params) {
    return API.post(`${comUrl}charging/page`, params);
  },
  setAccountCustom(params, type) {
    const url = `${comUrl}charging`;
    return type ? API.post(url, params) : API.put(url, params);
  },
  indentifyAuthInfo(pageSize, pageNo, params) {
    return API.get(`${comUrl}audit/list/${pageSize}/${pageNo}`, params);
  },
  enterpriseAuthInfo(shopId) {
    return API.get(`${comUrl}audit/shop/${shopId}`);
  },
  workbenchInfo(id) {
    return API.get(`${comUrl}audit/workbench/${id}`);
  },
  realNameInfo(id) {
    return API.get(`${comUrl}audit/realName/${id}`);
  },
  enterpriseAuth(params) {
    return API.post(`${comUrl}audit`, params);
  }
};

export default Account;